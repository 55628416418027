import { Component, Input, OnInit, Output, EventEmitter, Directive, HostListener } from '@angular/core';
// import {trigger, transition, style, animate, query, stagger, keyframes} from '@angular/animations';
import { environment } from '../environments/environment';
import { DbService } from './db.service';
import { Track } from './models/track';
import { FilterPipe } from './filter.pipe';
import { ActiveTrackService } from './active-track.service';
import { SearchTermService } from './search-term.service';
import { DownloadService } from './download.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { MobileStatusService } from './mobile-status.service';
import { SelectedFilterList } from './models/selected_filter_list';
import { SelectedFilterService } from './selected-filter.service';
import { TrackSelectionService } from './track-selection.service';
import { MobileStatus } from './models/mobile_status';
import raw_data from "../../tracktribe_data.json";
import { TrackShuttleCounter } from './models/track_counter';


// animation related
import { fadeAnimation } from './animations';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
    DbService,
    FilterPipe
  ],
  animations: [fadeAnimation]
})


export class AppComponent {


  // song_data : Track[] = raw_data;
  song_data : Track[] = [];
  main_window_height : number = 50;
  hero_logo_height : number = 22;
  cur_track : Track = null;
  IsTrackPlaying : boolean = false;
	search_filter : string = '';
  @Output() searchValueChanged = new EventEmitter<string>();
  interstitialShown : boolean = true; // enable this to restore interstitial
	interstitialVisible : boolean = false;
  interstitialTimer = null;

  dark = false;
  mobile : boolean = false;
  musicPageActive : boolean = false;
  
  // @HostListener('document:click', ['$event'])
  //   documentClick(event: MouseEvent) {
  //       console.log('click')
  //       this.testFilterSelectStatus(event);
  //   }

    // @HostListener('window:scroll', ['$event']) 
    //   onScroll($event){
    //     console.log($event);
    //     console.log("scrolling");
    //   } 

  constructor( private dbs : DbService, 
    private store : ActiveTrackService, 
    private search : SearchTermService, 
    private downloadService: DownloadService, 
    private router : Router,
    private mobileSizeService : MobileStatusService,
    private selectedFilterService : SelectedFilterService,
    private trackSelectionService : TrackSelectionService ) { 
  
  }

  // onScroll($event) {
  //   console.log('scrolling...')
  // }

  onResize($event) {
    let action : MobileStatus = {
      mobileStatus : $event.target.innerWidth > 768 ? false : true,
    }
    this.mobileSizeService.setState(action);
  }


  ngOnInit() {
    // this.dbs.getSongData().subscribe((data:Track[]) => {
    //   this.song_data = data;
    // });
    // console.log(this.song_data);

    // observe the current track
    this.store.state$.subscribe(state => { // subscribe to the cur_track from state
      this.cur_track = state.track;
      if (!this.interstitialShown && state.IsPlaying == true) {
        this.interstitialShown = true;
        this.startInterstitialSequence();
      }
    });

    // observe the search term
    this.search.state$.subscribe(term => { // subscribe to the search_filter from state
      this.search_filter = term.curSearchTerm;
      // this.filterListObject.search_filter = term.curSearchTerm;
      // console.log(this.filterListObject);
      this.updateSearch();
    });

    // observe the track shuttles
    this.trackSelectionService.state$.subscribe(skipStatus => { // subscribe to the search_filter from state
      // console.log('app: ' + JSON.stringify(skipStatus));
    });

    // set dark or light header
    this.router.events.subscribe(value => {
      this.dark = (this.router.url.toString() == '/gunnar' ||
      // this.router.url.toString() == '/' || 
      this.router.url.toString() == '/peoplemusic' || 
      this.router.url.toString() == '/peoplemusic-announce' || 
      this.router.url.toString() == '/404' || 
      this.router.url.toString() == '/albums' ||
      this.router.url.toString() == '/siena-sings' ) ? true : false;

      this.musicPageActive = this.router.url.toString() == '/music' ? true : false;
    });
    // this.dark = true;

    // observe mobile status service for screen width 
    this.mobileSizeService.state$.subscribe(mobile => {
      this.mobile = mobile.mobileStatus;
    });

    // this.selectedFilterService.state$.subscribe(sf => {
    //   this.filterListObject.selectedFilterList = sf;
    // });

    // this.filters.genre.sort();
    // this.filters.mood.sort();
    // this.filters.instruments.sort();
  }


  updateSearch() {
  	if (this.search_filter != undefined && this.search_filter.length == 0) {
      this.main_window_height = 50;
  		this.hero_logo_height = 22;
    } else {
  		this.main_window_height = 30;
      this.hero_logo_height = 17;
    }
  }


  startInterstitialSequence() {
    this.interstitialTimer = setTimeout( () => {
      this.interstitialVisible = true;
      // TODO : write to local storage or cookie
    },5000)
  }


  closeInterstitial() {
      this.interstitialVisible = false;
  }


  // determine if an option is currently 
  // part of the selected filters set
  IsSelected(item,group) {
    // switch(group) {
    //   case 'genre':
    //     return this.filterListObject.selectedFilterList.genre.includes(item.toLowerCase());
    //     break;
    //   case 'mood':
    //     return this.filterListObject.selectedFilterList.mood.includes(item.toLowerCase());
    //     break;
    //   case 'instruments':
    //     return this.filterListObject.selectedFilterList.instruments.includes(item.toLowerCase());
    //     break;
    //   case 'duration':
    //     return this.filterListObject.selectedFilterList.duration.includes(item.toLowerCase());
    //     break;
    //   default:
    //     return false;
    // }
    return false;
    // return group.includes(item);
  }


  // generic method for inserting any option into its 
  // corret list for filtering track results
  selectOption(option,group) {
    
    // control for single genre and mood behavior
    // if ( (group == this.selectedFilters.genre && this.selectedFilters.genre.length > 0)  || 
    //   (group == this.selectedFilters.mood && this.selectedFilters.mood.length > 0) ||
    //   (group == this.selectedFilters.duration && this.selectedFilters.duration.length > 0) ) {
      
    //   group.splice(0,1);

    // }


    if (!group.includes(option))
      group.push(option.toLowerCase());

    this.updateSelectedFilterService();
  }


  // generic method for removing any option into its 
  // correct list for filtering track results
  removeOption(option,group) {
    if (group.includes(option)) {
      let index = group.indexOf(option);
      group.splice(index,1);
    }

    this.updateSelectedFilterService();
  }


  // update the selectedFilterService
  // set of currently selected filters.
  // this is primarily for the songlist
  updateSelectedFilterService() {
    // let action : SelectedFilterList = {
    //   genre : this.selectedFilters.genre,
    //   mood : this.selectedFilters.mood,
    //   instruments : this.selectedFilters.instruments,
    //   duration : this.selectedFilters.duration,
    // }
    // this.selectedFilterService.setState(action);
  }


  // only a single toggle list can be open at once
  toggleFilterVisible(group) {
    // this.allFilters.forEach( f => {
    //   if (f.name == group) {
    //     f.status = !f.status;
    //     this.updateFilterControlStatus(group,f.status);
    //   } else {
    //     f.status = false;
    //     this.updateFilterControlStatus(f.name,f.status);
    //   }
    // });
  }


  updateFilterControlStatus(group,value) {
    // switch(group) { 
    //   case 'genre':
    //     this.filterGenreVisible = value;
    //     break;
    //   case 'mood':
    //     this.filterMoodVisible = value;
    //     break;
    //   case 'instrument':
    //     this.filterInstrumentVisible = value;
    //     break;
    //   case 'duration':
    //     this.filterLengthVisible = value;
    //     break;
    //   }
  }


  getFiltersOpen() {
    let anyOpen = false;
    // this.allFilters.forEach( f => {
    //   if (f.status == true) {
    //     anyOpen = true;
    //   }
    // });
    return anyOpen;
  }


  testFilterSelectStatus(event) {
    
    let target = event.target || event.srcElement || event.currentTarget;
    let idAttr = target.attributes.id;
    // console.log(target);

    if (idAttr && idAttr.nodeValue != undefined) {
      if (idAttr.nodeValue == "search_bar")
        this.closeAllFilters();

    }

    
    if ((idAttr == undefined && this.getFiltersOpen()) ) {
      this.closeAllFilters();
    }
  }


  closeAllFilters() {
    // this.filterGenreVisible = false;
    // this.filterMoodVisible = false;
    // this.filterInstrumentVisible = false;
    // this.filterLengthVisible = false;
    // this.allFilters.forEach( f => { f.status = false; });
  }

  closeFilter(group) {
    // switch(group) {
    //   case 'genre':
    //     this.filterGenreVisible = false;
    //     break;
    //   case 'mood':
    //     this.filterMoodVisible = false;
    //     break;
    //   case 'instruments':
    //     this.filterInstrumentVisible = false;
    //     break;
    //   case 'duration':
    //     this.filterLengthVisible = false;
    //     break;
    // }
    // this.allFilters.find( f => f.name == group ).status = false;
    // console.log(this.allFilters);
  }


  handleRewEvent() {
    let action : TrackShuttleCounter = {
      shuttleStatus : 'rewEvent-'+ Date.now(),
    }
    this.trackSelectionService.setState(action);
  }

  handleFFEvent() {
    // console.log("app level ff");
    let action : TrackShuttleCounter = {
      shuttleStatus : 'ffEvent-'+ Date.now(),
    }
    this.trackSelectionService.setState(action);
  }

  getDisplayWidth() : number {
    return window.innerWidth;
  }

}
