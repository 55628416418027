/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./global-nav/global-nav.component.ngfactory";
import * as i3 from "./global-nav/global-nav.component";
import * as i4 from "@angular/router";
import * as i5 from "./_services/auth.service";
import * as i6 from "./global-nav-dark/global-nav-dark.component.ngfactory";
import * as i7 from "./global-nav-dark/global-nav-dark.component";
import * as i8 from "@angular/common";
import * as i9 from "./music-player/music-player.component.ngfactory";
import * as i10 from "./music-player/music-player.component";
import * as i11 from "./active-track.service";
import * as i12 from "./current-playlist.service";
import * as i13 from "./components/video-player/video-player.component.ngfactory";
import * as i14 from "./components/video-player/video-player.component";
import * as i15 from "./video.service";
import * as i16 from "./instagram-dom.service";
import * as i17 from "@angular/platform-browser";
import * as i18 from "./filter.pipe";
import * as i19 from "./db.service";
import * as i20 from "@angular/common/http";
import * as i21 from "./app.component";
import * as i22 from "./search-term.service";
import * as i23 from "./download.service";
import * as i24 from "./mobile-status.service";
import * as i25 from "./selected-filter.service";
import * as i26 from "./track-selection.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: { "animation": [{ type: 7, name: "fadeAnimation", definitions: [{ type: 1, expr: "* => *", animation: [{ type: 11, selector: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }], options: { optional: true } }, { type: 11, selector: ":leave", animation: [{ type: 6, styles: { opacity: 1 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: "0.3s ease-in-out" }], options: { optional: true } }, { type: 11, selector: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: "0.3s ease-in-out" }], options: { optional: true } }], options: null }], options: {} }] } });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-global-nav", [], null, null, null, i2.View_GlobalNavComponent_0, i2.RenderType_GlobalNavComponent)), i1.ɵdid(1, 114688, null, 0, i3.GlobalNavComponent, [i4.Router, i5.AuthenticationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-global-nav-dark", [], null, null, null, i6.View_GlobalNavDarkComponent_0, i6.RenderType_GlobalNavDarkComponent)), i1.ɵdid(1, 114688, null, 0, i7.GlobalNavDarkComponent, [i4.Router, i5.AuthenticationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "main-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(2, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "content-window"]], [[24, "@fadeAnimation", 0]], [["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (_co.onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i8.ɵNgClassImpl, i8.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(7, 278528, null, 0, i8.NgClass, [i8.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(8, { "content-window-active-track": 0 }), (_l()(), i1.ɵeld(9, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(10, 212992, [["outlet", 4]], 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 5, "div", [["class", "player-container"]], null, null, null, null, null)), i1.ɵprd(512, null, i8.ɵNgStyleImpl, i8.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(13, 278528, null, 0, i8.NgStyle, [i8.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(14, { "display": 0 }), (_l()(), i1.ɵeld(15, 0, null, null, 1, "app-music-player", [], null, [[null, "rewEvent"], [null, "ffEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("rewEvent" === en)) {
        var pd_0 = (_co.handleRewEvent() !== false);
        ad = (pd_0 && ad);
    } if (("ffEvent" === en)) {
        var pd_1 = (_co.handleFFEvent() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i9.View_MusicPlayerComponent_0, i9.RenderType_MusicPlayerComponent)), i1.ɵdid(16, 114688, null, 0, i10.MusicPlayerComponent, [i11.ActiveTrackService, i12.CurrentPlaylistService], null, { rewEvent: "rewEvent", ffEvent: "ffEvent" }), (_l()(), i1.ɵeld(17, 0, null, null, 1, "app-video-player", [], null, null, null, i13.View_VideoPlayerComponent_0, i13.RenderType_VideoPlayerComponent)), i1.ɵdid(18, 114688, null, 0, i14.VideoPlayerComponent, [i15.VideoService, i16.InstagramDOMService, i17.DomSanitizer], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.dark; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.dark; _ck(_v, 4, 0, currVal_1); var currVal_3 = "content-window"; var currVal_4 = _ck(_v, 8, 0, (_co.cur_track != null)); _ck(_v, 7, 0, currVal_3, currVal_4); _ck(_v, 10, 0); var currVal_5 = _ck(_v, 14, 0, ((_co.cur_track != null) ? "flex" : "none")); _ck(_v, 13, 0, currVal_5); _ck(_v, 16, 0); _ck(_v, 18, 0); }, function (_ck, _v) { var currVal_2 = (i1.ɵnov(_v, 10).isActivated ? i1.ɵnov(_v, 10).activatedRoute : ""); _ck(_v, 5, 0, currVal_2); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵprd(4608, null, i18.FilterPipe, i18.FilterPipe, []), i1.ɵprd(512, null, i19.DbService, i19.DbService, [i20.HttpClient]), i1.ɵdid(3, 114688, null, 0, i21.AppComponent, [i19.DbService, i11.ActiveTrackService, i22.SearchTermService, i23.DownloadService, i4.Router, i24.MobileStatusService, i25.SelectedFilterService, i26.TrackSelectionService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i21.AppComponent, View_AppComponent_Host_0, {}, { searchValueChanged: "searchValueChanged" }, []);
export { AppComponentNgFactory as AppComponentNgFactory };
