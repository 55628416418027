/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./global-nav.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./global-nav.component";
import * as i5 from "../_services/auth.service";
var styles_GlobalNavComponent = [i0.styles];
var RenderType_GlobalNavComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GlobalNavComponent, data: { "animation": [{ type: 7, name: "listAnimation", definitions: [{ type: 1, expr: "* => *", animation: [{ type: 11, selector: ":enter", animation: { type: 6, styles: { opacity: 0, transform: "translateY(100%)" }, offset: null }, options: { optional: true } }, { type: 11, selector: ":enter", animation: { type: 12, timings: "200ms", animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 0.5, transform: "translateY(50%)", offset: 0.5 }, offset: null }, { type: 6, styles: { opacity: 1, transform: "translateY(0px)", offset: 1 }, offset: null }] }, timings: "0.6s ease-in-out" }] }, options: { optional: true } }], options: null }], options: {} }] } });
export { RenderType_GlobalNavComponent as RenderType_GlobalNavComponent };
export function View_GlobalNavComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "header-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(3, 1), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["class", "main-logo"], ["src", "./assets/images/header.png"]], null, null, null, null, null))], function (_ck, _v) { var currVal_2 = _ck(_v, 3, 0, "/"); _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_GlobalNavComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-global-nav", [], null, null, null, View_GlobalNavComponent_0, RenderType_GlobalNavComponent)), i1.ɵdid(1, 114688, null, 0, i4.GlobalNavComponent, [i2.Router, i5.AuthenticationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GlobalNavComponentNgFactory = i1.ɵccf("app-global-nav", i4.GlobalNavComponent, View_GlobalNavComponent_Host_0, {}, {}, []);
export { GlobalNavComponentNgFactory as GlobalNavComponentNgFactory };
