import { Injectable } from '@angular/core';
import { Store } from 'rxjs-observable-store';
import { Observable, BehaviorSubject } from 'rxjs';
import { TrackShuttleCounter } from './models/track_counter';


@Injectable({
  providedIn: 'root'
})


export class TrackSelectionService extends Store<TrackShuttleCounter>  {

  constructor() {
  	super(new TrackShuttleCounter());
   }
}
