import { Component, OnInit, HostListener } from '@angular/core';
import { trigger,style,transition,animate,keyframes,query,stagger } from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../_services';


@Component({
  selector: 'app-global-nav',
  templateUrl: './global-nav.component.html',
  styleUrls: ['./global-nav.component.css'],
  animations: [
    trigger('listAnimation', [
      transition('* => *', [
        query(':enter', style({ opacity: 0, transform: 'translateY(100%)' }), {optional: true}),

        query(':enter', stagger('200ms', [
          animate('0.6s ease-in-out', keyframes([
            style({opacity: .5, transform: 'translateY(50%)', offset: 0.5}),
            style({opacity: 1, transform: 'translateY(0px)', offset: 1.0}),
          ]))]), {optional: true})
      ])
    ])
  ]
})


export class GlobalNavComponent implements OnInit {

  link_list = ['music','experiences','games','info'];
  link_display_list = ['MUSIC','ABOUT','CONTACT'];
  links = [];
  navListVisible = false;

  // @HostListener('window:scroll', ['$event']) 
  // onScroll($event) {
  //   console.log($event);
  //   console.log("scrolling");
  // }

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
     // handle scrolling to fragments
    // this.route.fragment.subscribe(f => {
    //   const element = document.querySelector("#" + f)
    //   if (element) element.scrollIntoView({behavior: "smooth"})
    // })
  }

  get currentUser() { return this.authenticationService.currentUserValue; }
  get hasSubscription() { return this.currentUser && this.currentUser.subscriptionId }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/']);
  }

  toggleNavList() {
    this.navListVisible = !this.navListVisible;
    // this.populateLinks();
  }

  populateLinks() {
    if (this.navListVisible) {
      for (let link of this.link_list) {
        this.links.push(link);
      }
    } else {
      this.links = [];
    }
  }


}
