import { Component, OnInit, Input, Output , EventEmitter } from '@angular/core';
import { Video } from '../../models/video';
import { ActiveVideo } from '../../models/active_video';
import { VideoService } from '../../video.service';
import { InstagramDOMService } from '../../instagram-dom.service';
import { DomSanitizer } from '@angular/platform-browser';

import video_data from "./config.json";


@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.css']
})


export class VideoPlayerComponent implements OnInit {
	
	videos : any[] = video_data;

  curVideo : Video = null;
  safeURL;
  safeStyle;
  innerStyle;
  @Output() closeEvent = new EventEmitter<string>();

  constructor(private store: VideoService, 
    private instagram: InstagramDOMService,
    private _sanitizer: DomSanitizer) { 
      this.innerStyle = this._sanitizer.bypassSecurityTrustStyle("position:absolute;top:0;left:0;width:100%;height:100%;")
  }

  ngOnInit() {
    this.store.state$.subscribe(state => { // subscribe to the cur_trackList from state
      this.curVideo = state.video;
      if (this.curVideo) {
        this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(this.curVideo.url);
        this.safeStyle = this._sanitizer.bypassSecurityTrustStyle(this.curVideo.style);
      } else {
        this.safeURL = null
        this.safeStyle = null
      }
      
    })
  }

  async instagramDelay() {
    await this.delay(300);
    this.instagram.processEmbeddedInstagramPosts();
    console.log('processEmbeddedInstagramPosts');
  }

delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}

  setCurrentVideo(video) {
    let targetVideo = {
      title: video.title,
      url: video.src,
      style: video.style,
      type: video.type
    }
    let action : ActiveVideo = {
      video : targetVideo,
    }
    this.store.setState(action);
  }

  close() {
      let action : ActiveVideo = {
        video : null,
      }
      this.store.setState(action);
  }

}
