/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./video-player.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./video-player.component";
import * as i4 from "../../video.service";
import * as i5 from "../../instagram-dom.service";
import * as i6 from "@angular/platform-browser";
var styles_VideoPlayerComponent = [i0.styles];
var RenderType_VideoPlayerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VideoPlayerComponent, data: {} });
export { RenderType_VideoPlayerComponent as RenderType_VideoPlayerComponent };
function View_VideoPlayerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [], [[8, "style", 2]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "iframe", [["allow", "autoplay; fullscreen; picture-in-picture"], ["allowfullscreen", ""], ["frameborder", "0"]], [[8, "src", 5], [8, "style", 2]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.curVideo.style; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.safeURL; var currVal_2 = _co.innerStyle; _ck(_v, 2, 0, currVal_1, currVal_2); }); }
export function View_VideoPlayerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "player-container"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "player-container-active": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["class", "close-btn"], ["src", "../assets/images/delete.png"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideoPlayerComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "player-container"; var currVal_1 = _ck(_v, 3, 0, _co.curVideo); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.curVideo; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_VideoPlayerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-video-player", [], null, null, null, View_VideoPlayerComponent_0, RenderType_VideoPlayerComponent)), i1.ɵdid(1, 114688, null, 0, i3.VideoPlayerComponent, [i4.VideoService, i5.InstagramDOMService, i6.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VideoPlayerComponentNgFactory = i1.ɵccf("app-video-player", i3.VideoPlayerComponent, View_VideoPlayerComponent_Host_0, {}, { closeEvent: "closeEvent" }, []);
export { VideoPlayerComponentNgFactory as VideoPlayerComponentNgFactory };
