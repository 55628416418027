import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'filter',
  pure: false
})


export class FilterPipe implements PipeTransform {
  	
  	transform(items: any[], filterListObject: any ): any[] {
  		
		  var version = 3;
		  var timeRangeMap = {
			'0:00 - 1:00': {
				start : 0,
				end : 60
			},
			'1:01 - 3:00': {
				start : 61,
				end : 180
			},
			'3:01 - 5:00': {
				start : 181,
				end : 300
			},
			'5:01+': {
				start : 301,
				end : 1000000
			}
		  }

  		function getTrackSeconds(val) : number {
  			let arr = val.split(':')

			return (parseInt(arr[0]) * 3600) + (parseInt(arr[1]) * 60) + parseInt(arr[2]);
		}

		function isInTimeRange(val,timeRange) : boolean {
			let start,end;
			let match = false;
			timeRange.some(t => {
				if (timeRangeMap[t].start < val && val < timeRangeMap[t].end) {
					match = true;
					return match;
				} 

			})
			return match;
			
		}


		function groupTest(valString,testGroup, fullMatch = false) {
			let isInGroup = false;
			let keepGoing = true;
			let vals = valString.split(',');
			if (fullMatch) { // every item in testGroup must be in the value string
				
				isInGroup = true;
				testGroup.forEach(v => {
					if (!vals.includes(v.toLowerCase())) {
						isInGroup = false;
					}
				})
				
			} else {

				vals.forEach(v => { // any single match will return true
					if (keepGoing) {
						if (testGroup.includes(v.toLowerCase())) {
							isInGroup = true;
							keepGoing = false;
						}
					}
					
				})

			}
			
			return isInGroup;		
		}


		function processSearchTerm(items) : any[] {
			let finalItems = [];
			if ( filterListObject.search_filter != undefined && filterListObject.search_filter.length > 0 ) {
	    		items.forEach(it => {
	    			let match = 0;
	    			if ( it.title.toLowerCase().includes(filterListObject.search_filter.toLowerCase()) ||
	    				it.genre.toLowerCase().includes(filterListObject.search_filter.toLowerCase()) || 
	    				it.mood.toLowerCase().includes(filterListObject.search_filter.toLowerCase()) ||
	    				it.mood_synonyms.includes(filterListObject.search_filter.toLowerCase()) || 
	    				it.keywords.includes(filterListObject.search_filter.toLowerCase()) ) {
	    				match++;
	    			}

	    			if (match > 0 && !finalItems.find(x => x.isrc == it.isrc)) {
						finalItems.push(it);
					}
	    		})
	    	} else {
	    		finalItems = items
	    	}
	    	return finalItems;
		}
	  	

  		let filteredItems = [];


	    if(!items) {
	    	return [];
	    } else if ( filterListObject.selectedFilterList.genre.length == 0 &&
	    			filterListObject.selectedFilterList.mood.length == 0 && 
	    			filterListObject.selectedFilterList.instruments.length == 0 && 
	    			filterListObject.selectedFilterList.duration.length == 0 ) {
	    	return processSearchTerm(items);
	    } else {
			let matchesNeeded = [];

	    	switch(version) {
	    	// 	case 1:
		    // 		items.forEach( it => {
			   //  		let match = 0;
						
			   //  		// genre
						// if ( filterListObject.selectedFilterList.genre.length > 0 && filterListObject.selectedFilterList.genre.find(x => x == it.genre.toLowerCase() ) != undefined ) {					  
						// 	match++;

						// 	// check against mood synonyms
						// 	filterListObject.selectedFilterList.genre.forEach( g => {
						// 		if (it.mood_synonyms.includes(g.toLowerCase()))
						// 			match++;

						// 		if (it.keywords.includes(g.toLowerCase()))
						// 			match++;
						// 	})

						// }

						// // mood
						// if ( filterListObject.selectedFilterList.mood.length > 0 ) {	
							
						// 	if ( filterListObject.selectedFilterList.mood.find(x => x == it.mood.toLowerCase() ) != undefined )				  
						// 		match++;

						// 	// check against mood synonyms
						// 	filterListObject.selectedFilterList.mood.forEach( g => {
						// 		if (it.mood_synonyms.includes(g.toLowerCase()))
						// 			match++;

						// 		if (it.keywords.includes(g.toLowerCase()))
						// 			match++;
						// 	})
						// }

						// // instruments
						// if (filterListObject.selectedFilterList.instruments.length > 0) {
						// 	filterListObject.selectedFilterList.instruments.forEach( i => {
						// 		if (it.instruments.includes(i))
						// 			match++;
						// 	})
						// }

						// //duration
						// if (filterListObject.selectedFilterList.duration.length > 0) {
						// 	let trackDuration = getTrackSeconds(it.duration);
						// 	filterListObject.selectedFilterList.duration.forEach( d => {
						// 		if (isInTimeRange(trackDuration,d))
						// 			match++;
						// 	})
						// }


						// // eliminate duplicates
						// if (match > 0 && !filteredItems.find(x => x.isrc == it.isrc)) {
						// 	// console.log("legit")
						// 	filteredItems.push(it);
						// } 		

			   //  	})
		    // 		break;
		    	case 2:

		    		if (filterListObject.selectedFilterList.genre != '')
		    			matchesNeeded.push('genre');

		    		if (filterListObject.selectedFilterList.mood != '')
		    			matchesNeeded.push('mood');

		    		if (filterListObject.selectedFilterList.instruments != '')
		    			matchesNeeded.push('instruments');

		    		if (filterListObject.selectedFilterList.duration != '')
		    			matchesNeeded.push('duration');


		    		items.forEach( it => {
						let match = true;
						if ( matchesNeeded.includes('genre') && filterListObject.selectedFilterList.genre.toLowerCase() != it.genre.toLowerCase() ) {
							match = false;
						}
						if ( matchesNeeded.includes('mood') && filterListObject.selectedFilterList.mood.toLowerCase() != it.mood.toLowerCase() && match) 
							match = false;

						if ( matchesNeeded.includes('instruments') && it.instruments.toLowerCase() != filterListObject.selectedFilterList.instruments.toLowerCase() && match  ) 
							match = false;
						
						if ( matchesNeeded.includes('duration') && match ) {
							// let keepGoing = true;
							// filterListObject.selectedFilterList.duration.forEach(d => {
								// if (keepGoing) {
									if (isInTimeRange(getTrackSeconds(it.duration),filterListObject.selectedFilterList.duration)) {
										match = true;
									} else {
										match = false;
									}
									// 	keepGoing = false;
									// } else
									// 	match = false;
								// }
							// })
						} 
						
						// eliminate duplicates
						if (match && !filteredItems.find(x => x.isrc == it.isrc)) {
							filteredItems.push(it);
						}		

			    	})
					break;
				case 3:

					if (filterListObject.selectedFilterList.genre.length > 0)
						matchesNeeded.push('genre');

					if (filterListObject.selectedFilterList.mood.length > 0)
						matchesNeeded.push('mood');

					if (filterListObject.selectedFilterList.instruments.length > 0)
						matchesNeeded.push('instruments');

					if (filterListObject.selectedFilterList.duration.length > 0)
						matchesNeeded.push('duration');


					items.forEach( it => {
						let match = true;
						if ( matchesNeeded.includes('genre') && !filterListObject.selectedFilterList.genre.includes(it.genre.toLowerCase()) ) {
							match = false;
						}
						if ( matchesNeeded.includes('mood') && !filterListObject.selectedFilterList.mood.includes(it.mood.toLowerCase()) && match) 
							match = false;

						// improve instruments matching
						if ( matchesNeeded.includes('instruments') && match ) {
							if (!groupTest(it.instruments.toLowerCase(),filterListObject.selectedFilterList.instruments, true))
								match = false;
						}
						
						if ( matchesNeeded.includes('duration') && match ) {
							// let keepGoing = true;
							// filterListObject.selectedFilterList.duration.forEach(d => {
								// if (keepGoing) {
									if (isInTimeRange(getTrackSeconds(it.duration),filterListObject.selectedFilterList.duration)) {
										match = true;
									} else {
										match = false;
									}
									// 	keepGoing = false;
									// } else
									// 	match = false;
								// }
							// })
						} 
						
						// eliminate duplicates
						if (match && !filteredItems.find(x => x.isrc == it.isrc)) {
							filteredItems.push(it);
						}		

					})
					break;

	    	}
	    	
	    	//now test against the searchTerm
	    	return processSearchTerm(filteredItems);

	    }



	    // return finalItems;

   }

}

// (it.title != null && flatSearchList.includes(it.title.toLowerCase())) ||
// 						(it.genre != null && flatSearchList.includes(it.genre.toLowerCase())) ||
// 						(it.keywords != null && flatSearchList.includes(it.keywords.toLowerCase())) ||
// 						(it.instruments != null && groupTest(it.instruments.toLowerCase(),flatSearchList) ) ||
// 						(it.mood_synonyms != null && groupTest(it.mood_synonyms.toLowerCase(),flatSearchList) ) ||
// 						(it.mood != null && flatSearchList.includes(it.mood.toLowerCase()) ) 
