
<div class='main-container'>
  
	<app-global-nav *ngIf="!dark"></app-global-nav>
	<app-global-nav-dark *ngIf="dark"></app-global-nav-dark>

 

	<div class="content-window" 
		[@fadeAnimation]="outlet.isActivated ? outlet.activatedRoute : ''" 
		(window:resize)="onResize($event)"
		[ngClass]="{
			'content-window-active-track': cur_track != null
		}">
		<router-outlet #outlet="outlet" ></router-outlet>
	</div>

</div>

<div class="player-container" [ngStyle]="{'display': cur_track != null ? 'flex' : 'none'}">
  <app-music-player (rewEvent)="handleRewEvent()" (ffEvent)="handleFFEvent()"></app-music-player>
</div>
<app-video-player ></app-video-player>
<!-- <app-interstitial [visible]="interstitialVisible" (panelClosed)="closeInterstitial()"></app-interstitial> -->
