import { Injectable } from '@angular/core';
import { Store } from 'rxjs-observable-store';
import { Observable, BehaviorSubject } from 'rxjs';
import { Track } from './models/track';
import { ActiveTrack } from './models/active_track';


@Injectable({
  providedIn: 'root'
})


export class ActiveTrackService extends Store<ActiveTrack> {

  constructor() { 
  	super(new ActiveTrack());
  	// this._trackState$ = new BehaviorSubject(null)
   // 	this.trackState$ = this._trackState$.asObservable();
  }


  setSongData(data) {
    // super.state.song_data = data;
    // console.log(super.state.song_data);
  }


  goNextTrack () {
      //return this._trackState$.getValue();
      // let t = super.state.track.id;
      // t++;
      // if (t > 100)
      //   t = 1;

      // let result = super.state.song_data.filter(obj => {
      //   return obj.id === t;
      // })
      // console.log("going to : " + t);
  }

  goPreviousTrack() {
      // let t = super.state.track.id;
      // t--;
      // if (t < 1)
      //   t = 100;
      // let result = super.state.song_data.filter(obj => {
      //   return obj.id === t;
      // })
      // console.log("going to : " + t);
  }

  


}
