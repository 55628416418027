import { Store } from 'rxjs-observable-store';
import { MobileStatus } from './models/mobile_status';
import * as i0 from "@angular/core";
export class MobileStatusService extends Store {
    constructor() {
        super(new MobileStatus());
        this.mobile = false;
    }
    updateScreenWidth(width) {
        if (width <= 768)
            this.mobile = true;
        else
            this.mobile = false;
        // console.log("mobile service status : " + this.mobile);
    }
}
MobileStatusService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MobileStatusService_Factory() { return new MobileStatusService(); }, token: MobileStatusService, providedIn: "root" });
