import { Injectable } from '@angular/core';
import { Store } from 'rxjs-observable-store';
import { Observable, BehaviorSubject } from 'rxjs';
import { SearchTerm } from './models/search_term';


@Injectable({
  providedIn: 'root'
})


export class SearchTermService extends Store<SearchTerm> {

  constructor() { 
  	super(new SearchTerm());
  }

}
