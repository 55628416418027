import { Store } from 'rxjs-observable-store';
import { SelectedFilterList } from './models/selected_filter_list';
import * as i0 from "@angular/core";
export class SelectedFilterService extends Store {
    // selectedFilters : SelectedFilterList;
    constructor() {
        super(new SelectedFilterList());
    }
}
SelectedFilterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SelectedFilterService_Factory() { return new SelectedFilterService(); }, token: SelectedFilterService, providedIn: "root" });
