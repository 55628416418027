import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';


@Injectable({
  providedIn: 'root'
})


export class DownloadService {


  constructor(private http: HttpClient) { }


  // downloadFile(filePath):void
  // {
  //   this.getFile(filePath)
  //   .subscribe(fileData => 
  //     {
  //     let b:any = new Blob([fileData], { type: 'application/zip' });
  //     var url= window.URL.createObjectURL(b);
  //       window.open(url);
  //     }
  //   );
  // }
 

  // public getFile(path: string):Observable<any>{
  //   let options = new RequestOptions({responseType: ResponseContentType.Blob});
  //   return this.http.get(path, options)
  //       .map((response: Response) => <Blob>response.blob())  ;
  // }
}
