<div class="header-bar">
  <a [routerLink]="['/']"><img class='main-logo' src='./assets/images/header.png'></a>
	
  <!--
  <div class='main-nav-container'>
	<a [routerLink]="'/artists'" class="nav-button">Artists</a>
	<a href="mailto:info@tracktribe.com" class="nav-button nav-button-last" target="new">Contact</a>
  </div>
	-->

  <!-- mini nav -->
  <!--
  <div class="nav-mini" (click)="toggleNavList()">
	<div class="nav-mini-bar" [ngClass]="{'nav-mini-bar-top-vis' : navListVisible}"></div>
	<div class="nav-mini-bar" [ngClass]="{'nav-mini-bar-mid-vis' : navListVisible}"></div>
	<div class="nav-mini-bar" [ngClass]="{'nav-mini-bar-bot-vis' : navListVisible}"></div>
  </div>
	

  <div class="nav-mini-list" [ngClass]="{'list-visible' : navListVisible}" >
	<div class="mini-nav-button"><a [routerLink]="'/'" fragment="top" (click)="toggleNavList()">Home</a></div>
	<div class="mini-nav-button"><a [routerLink]="'/artists'" (click)="toggleNavList()">Artists</a></div>
	<div class='mini-nav-button'><a href="mailto:info@tracktribe.com" target="new" (click)="toggleNavList()">Contact</a></div>
  </div>
  -->
</div>

