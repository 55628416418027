export class SelectedFilterList
{
  genre : string[];
  mood : string[];
  instruments : string[];
  duration : string[];

  constructor() {
    this.genre = [];
    this.mood = [];
    this.instruments = [];
    this.duration = [];
  }
}