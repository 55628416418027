import { Injectable } from '@angular/core';

declare namespace instgrm {
  namespace Embeds {
    function process(): void;
  }
}

@Injectable()
export class InstagramDOMService {
  public processEmbeddedInstagramPosts(): void {
    if (window['instgrm']) {
      console.log('processing instagram posts')
      instgrm.Embeds.process()
    } else {
      console.log('no instagram component found')
    }
  }
}