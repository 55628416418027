import { EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
export class DbService {
    constructor(http) {
        this.http = http;
        this.song_data = [];
        this.dbLoaded = new EventEmitter;
    }
    getSongData() {
        return this.http.get(environment.SERVER_URL + '/api/tracks');
    }
}
DbService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DbService_Factory() { return new DbService(i0.ɵɵinject(i1.HttpClient)); }, token: DbService, providedIn: "root" });
