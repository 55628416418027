/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./music-player.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../audio-load.directive";
import * as i3 from "@angular/common";
import * as i4 from "../click-stop-propigation.directive";
import * as i5 from "../../../node_modules/ng5-slider/ng5-slider.ngfactory";
import * as i6 from "@angular/forms";
import * as i7 from "ng5-slider";
import * as i8 from "./music-player.component";
import * as i9 from "../active-track.service";
import * as i10 from "../current-playlist.service";
var styles_MusicPlayerComponent = [i0.styles];
var RenderType_MusicPlayerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MusicPlayerComponent, data: {} });
export { RenderType_MusicPlayerComponent as RenderType_MusicPlayerComponent };
function View_MusicPlayerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "info-details"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "track-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "artist-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getDisplayName(); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.cur_track.provider; _ck(_v, 4, 0, currVal_1); }); }
function View_MusicPlayerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["audioPlayer", 1]], null, 3, "audio", [["appAudioLoad", ""], ["preload", "metadata"]], null, [[null, "onloadedmetadata"]], function (_v, en, $event) { var ad = true; if (("onloadedmetadata" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onLoaded($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.AudioLoadDirective, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "source", [["type", "audio/mpeg"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Your browser does not support the audio element. "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "./assets/audio/", _co.cur_track.title, " "); _ck(_v, 2, 0, currVal_0); }); }
export function View_MusicPlayerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { audioplayer: 0 }), i1.ɵqud(671088640, 2, { mpsliderhandle: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 23, "div", [["class", "player-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "info-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "player-controls-mobile"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["class", "control play"]], [[8, "src", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.checkPlay() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MusicPlayerComponent_1)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 16, "div", [["appClickStopPropigation", ""], ["class", "control-group"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 16384, null, 0, i4.ClickStopPropigationDirective, [], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "player-controls"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "img", [["class", "control skip"], ["src", "./assets/images/rwd_ico.png"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectPreviousTrack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "img", [["class", "control play"]], [[8, "src", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.checkPlay() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "img", [["class", "control skip"], ["src", "./assets/images/ffwd_ico.png"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectNextTrack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 8, "div", [["class", "track-time"]], null, [[null, "mousedown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.grabbedHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "div", [["class", "time-value"]], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, ["", ""])), (_l()(), i1.ɵeld(17, 0, null, null, 3, "ng5-slider", [["class", "ng5-slider"]], [[2, "vertical", null], [2, "animate", null], [1, "disabled", 0]], [[null, "valueChange"], [null, "userChangeEnd"], [null, "userChange"], [null, "mousedown"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19).onResize($event) !== false);
        ad = (pd_0 && ad);
    } if (("valueChange" === en)) {
        var pd_1 = ((_co.currentTrackTime = $event) !== false);
        ad = (pd_1 && ad);
    } if (("userChangeEnd" === en)) {
        var pd_2 = (_co.releasedHandle() !== false);
        ad = (pd_2 && ad);
    } if (("userChange" === en)) {
        var pd_3 = (_co.updateTrackTime($event) !== false);
        ad = (pd_3 && ad);
    } if (("mousedown" === en)) {
        var pd_4 = (_co.grabbedHandle($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i5.View_ɵa_0, i5.RenderType_ɵa)), i1.ɵprd(5120, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.ɵa]), i1.ɵdid(19, 4964352, null, 1, i7.ɵa, [i1.Renderer2, i1.ElementRef, i1.ChangeDetectorRef, i1.NgZone], { value: [0, "value"], options: [1, "options"] }, { valueChange: "valueChange", userChange: "userChange", userChangeEnd: "userChangeEnd" }), i1.ɵqud(335544320, 3, { tooltipTemplate: 0 }), (_l()(), i1.ɵeld(21, 0, null, null, 1, "div", [["class", "time-value right-aligned"]], null, null, null, null, null)), (_l()(), i1.ɵted(22, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MusicPlayerComponent_2)), i1.ɵdid(24, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(25, 0, null, null, 0, "div", [["class", "secondary-controls"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.cur_track != null); _ck(_v, 7, 0, currVal_1); var currVal_7 = _co.currentTrackTime; var currVal_8 = _co.options; _ck(_v, 19, 0, currVal_7, currVal_8); var currVal_10 = (_co.cur_track != null); _ck(_v, 24, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "./assets/images/", _co.play_control_image, ""); _ck(_v, 5, 0, currVal_0); var currVal_2 = i1.ɵinlineInterpolate(1, "./assets/images/", _co.play_control_image, ""); _ck(_v, 12, 0, currVal_2); var currVal_3 = _co.getDisplayTrackCurrentTime(); _ck(_v, 16, 0, currVal_3); var currVal_4 = i1.ɵnov(_v, 19).sliderElementVerticalClass; var currVal_5 = i1.ɵnov(_v, 19).sliderElementAnimateClass; var currVal_6 = i1.ɵnov(_v, 19).sliderElementDisabledAttr; _ck(_v, 17, 0, currVal_4, currVal_5, currVal_6); var currVal_9 = _co.getDisplayTrackDuration(); _ck(_v, 22, 0, currVal_9); }); }
export function View_MusicPlayerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-music-player", [], null, null, null, View_MusicPlayerComponent_0, RenderType_MusicPlayerComponent)), i1.ɵdid(1, 114688, null, 0, i8.MusicPlayerComponent, [i9.ActiveTrackService, i10.CurrentPlaylistService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MusicPlayerComponentNgFactory = i1.ɵccf("app-music-player", i8.MusicPlayerComponent, View_MusicPlayerComponent_Host_0, {}, { rewEvent: "rewEvent", ffEvent: "ffEvent" }, []);
export { MusicPlayerComponentNgFactory as MusicPlayerComponentNgFactory };
