import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appAudioLoad]'
})
export class AudioLoadDirective {
	
@HostListener("onloadedmetadata", ["$event"])
    public onLoaded(event: any): void
    {
        console.log(event);
    }

  constructor() { }

}
