import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

// import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { SongListComponent } from './song-list/song-list.component';
import { DonateComponent } from './donate/donate.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { LoginComponent } from './login/login.component'
import { HomeComponent } from './home/home.component';

import { SubscriptionGuard } from './_helpers';
import { ArtistListComponent } from './artist-list/artist-list.component';
import { ArtistComponent } from './artist/artist.component';
import { PeoplemusicComponent } from './peoplemusic/peoplemusic.component';
import { OtisPlayerComponent } from './otis-player/otis-player.component';
import { AlbumsComponent } from './albums/albums.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SienaSingsComponent } from './siena-sings/siena-sings.component';
import { LaunchComponent } from './launch/launch.component';


const routes: Routes = [
  // { path: '' , redirectTo: '/siena-sings', pathMatch: 'full' },
	
  { path: '' , component: LaunchComponent },
  // { path: 'gunnar' , component : GunnarComponent },
  // { path: 'peoplemusic-announce' , component : PeoplemusicComponent },
  // { path: 'siena-sings' , component : SienaSingsComponent },
  // { path: 'peoplemusic' , component : OtisPlayerComponent },
	// { path: 'about' , component : AboutComponent },
	// { path: 'contact' , component : ContactComponent },
	// { path: 'login', component: LoginComponent },
	// { path: 'music', component: LaunchComponent },
	// { path: 'music', component: SongListComponent },
	// { path: 'donate', component: DonateComponent },
	// { path: 'subscription', component: SubscriptionComponent, canActivate: [SubscriptionGuard] },
  // { path: 'artists' , component : ArtistListComponent },
  // { path: 'artist/:id' , component : ArtistComponent },
  // { path: 'play-this-beat' , component : PlayThisBeatComponent },
  // { path: 'how-it-works' , component : HowItWorksComponent },
  // { path: '404' , component : LaunchComponent },
  { path: '**' , redirectTo: '/'},
  // { path: '**' , redirectTo: '/'},
  // { path: '**' , component : NotFoundComponent },

];

const routerOptions: ExtraOptions = {
  // scrollPositionRestoration: 'enabled',
  // anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload',
  // enableTracing: true,
  // scrollOffset: [0, -64],
};


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, routerOptions)
  ],
  exports: [
  	RouterModule
  ]
})


export class AppRoutingModule { }
