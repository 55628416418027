import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { VideoService } from '../../video.service';
import { InstagramDOMService } from '../../instagram-dom.service';
import { DomSanitizer } from '@angular/platform-browser';
import video_data from "./config.json";
export class VideoPlayerComponent {
    constructor(store, instagram, _sanitizer) {
        this.store = store;
        this.instagram = instagram;
        this._sanitizer = _sanitizer;
        this.videos = video_data;
        this.curVideo = null;
        this.closeEvent = new EventEmitter();
        this.innerStyle = this._sanitizer.bypassSecurityTrustStyle("position:absolute;top:0;left:0;width:100%;height:100%;");
    }
    ngOnInit() {
        this.store.state$.subscribe(state => {
            this.curVideo = state.video;
            if (this.curVideo) {
                this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(this.curVideo.url);
                this.safeStyle = this._sanitizer.bypassSecurityTrustStyle(this.curVideo.style);
            }
            else {
                this.safeURL = null;
                this.safeStyle = null;
            }
        });
    }
    instagramDelay() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.delay(300);
            this.instagram.processEmbeddedInstagramPosts();
            console.log('processEmbeddedInstagramPosts');
        });
    }
    delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    setCurrentVideo(video) {
        let targetVideo = {
            title: video.title,
            url: video.src,
            style: video.style,
            type: video.type
        };
        let action = {
            video: targetVideo,
        };
        this.store.setState(action);
    }
    close() {
        let action = {
            video: null,
        };
        this.store.setState(action);
    }
}
