import { Injectable } from '@angular/core';
import { Store } from 'rxjs-observable-store';
import { Observable, BehaviorSubject } from 'rxjs';
// import { Track } from './models/track';
import { ActiveTrack } from './models/active_track';
import { CurrentPlaylist } from './models/current-playlist';


@Injectable({
  providedIn: 'root'
})


export class CurrentPlaylistService extends Store<CurrentPlaylist> {

  constructor() {
  	super(new CurrentPlaylist());
  }
  
}
