import { Injectable } from '@angular/core';
import { Store } from 'rxjs-observable-store';
import { ActiveVideo } from './models/active_video';


@Injectable({
  providedIn: 'root'
})


export class VideoService extends Store<ActiveVideo>{

  constructor() { 
    super(new ActiveVideo());
  }
}
