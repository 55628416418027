<div class="player-container" [ngClass]="{ 'player-container-active' : curVideo }" >
    <img class="close-btn" src="../assets/images/delete.png" (click)="close()" />
    <div *ngIf="curVideo">
        <div [style]="curVideo.style">
            <iframe [src]='safeURL' 
            [style]='innerStyle'
            frameborder="0" 
            allow="autoplay; fullscreen; picture-in-picture" 
            allowfullscreen>
            </iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
    </div>
</div>

<!-- og -->
<!-- <iframe 
    class="player-iframe" 
    [src]='safeURL' 
    title="{{curVideo.title}}" 
    frameborder="0" 
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
</iframe> -->
