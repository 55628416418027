import { Injectable, Output, EventEmitter } from '@angular/core';
import { Observable , Observer } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Song } from './models/song';
import { environment } from '../environments/environment';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
})


export class DbService {
  song_data : Song[] = [];
  @Output() dbLoaded = new EventEmitter


  constructor(private http: HttpClient) {
  	
  }


  getSongData() : Observable<Song[]> {
  	return this.http.get<Song[]>(environment.SERVER_URL+'/api/tracks');
  }
}
