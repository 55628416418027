<div class="launch-container">

	<div class="hero-block hero-launch" [ngClass]="{'hero-block-active-track': curTrack != null }" id="home">
		<div class="launch-hero-container">
			<img class="hero" src="./assets/images/tracktribe_logo_whtxt.png">
			
			<div class="hero-social-block"> 
				<a href="https://www.instagram.com/tracktribemusic/" target="new">
					<img class="hero-social-icon" src="./assets/images/social/white/instagram.png">
				</a>
				<a href="https://open.spotify.com/artist/4YKdSLESMOqzq5QSMAjCmv?si=ipxLrOiKSVCd75ltPXgQnA" target="new">
					<img class="hero-social-icon" src="./assets/images/social/spotify.png">
				</a>
				<a href="https://www.youtube.com/channel/UCALNf7YM2pEGJvIHf1zxftA" target="new">
					<img class="hero-social-icon" src="./assets/images/social/white/youtube.png">
				</a>
	  		 </div> 
		</div>

	</div>

	<!-- <app-footer></app-footer> -->

</div>

