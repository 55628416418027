import { Component, OnInit } from '@angular/core';
import { trigger,style,transition,animate,keyframes,query,stagger } from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../_services';


@Component({
  selector: 'app-global-nav-dark',
  templateUrl: './global-nav-dark.component.html',
  styleUrls: ['./global-nav-dark.component.css'],
  animations: [
    trigger('listAnimation', [
      transition('* => *', [
        query(':enter', style({ opacity: 0, transform: 'translateY(100%)' }), {optional: true}),

        query(':enter', stagger('200ms', [
          animate('0.6s ease-in-out', keyframes([
            style({opacity: .5, transform: 'translateY(50%)', offset: 0.5}),
            style({opacity: 1, transform: 'translateY(0px)', offset: 1.0}),
          ]))]), {optional: true})
      ])
    ])
  ]
})


export class GlobalNavDarkComponent implements OnInit {


  link_list = ['music','experiences','games','info'];
  link_display_list = ['MUSIC','ABOUT','CONTACT'];
  links = [];
  navListVisible = false;


  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }


  ngOnInit() {
  }

  toggleNavList() {
    this.navListVisible = !this.navListVisible;
  }


  populateLinks() {
    if (this.navListVisible) {
      for (let link of this.link_list) {
        this.links.push(link);
      }
    } else {
      this.links = [];
    }
  }

}
