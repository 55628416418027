import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule , Validators } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Router } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';

import { AppComponent } from './app.component';
import { GlobalNavComponent } from './global-nav/global-nav.component';
import { MainSearchComponent } from './main-search/main-search.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { FilterPipe } from './filter.pipe';
import { SongListComponent } from './song-list/song-list.component';

// import { NgxAudioPlayerModule } from 'ngx-audio-player';
import { MusicPlayerComponent } from './music-player/music-player.component';
import { ClickStopPropigationDirective } from './click-stop-propigation.directive';
import { ActiveTrackService } from './active-track.service';
import { AudioLoadDirective } from './audio-load.directive';
import { Ng5SliderModule } from 'ng5-slider';
import { AppRoutingModule } from './app-routing.module';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
// import { InterstitialComponent } from './interstitial/interstitial.component';
import { HomeComponent } from './home/home.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { FooterComponent } from './footer/footer.component';
import { DonateComponent } from './donate/donate.component';
import { JwtInterceptor } from './_helpers';
import { SubscriptionComponent } from './subscription/subscription.component';
import { LoginComponent } from './login/login.component';
import { ArtistListComponent } from './artist-list/artist-list.component';
import { ArtistComponent } from './artist/artist.component';
import { GlobalNavDarkComponent } from './global-nav-dark/global-nav-dark.component';
// import { GunnarComponent } from './gunnar/gunnar.component';
import { PeoplemusicComponent } from './peoplemusic/peoplemusic.component';
import { SanitizeHtmlPipe } from './sanitize-html.pipe';
import { OtisPlayerComponent } from './otis-player/otis-player.component';
import { AlbumsComponent } from './albums/albums.component';
import { FeaturePlayerComponent } from './feature-player/feature-player.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PlayThisBeatComponent } from './play-this-beat/play-this-beat.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
// import { IgFeedComponent } from './ig-feed/ig-feed.component';
import { SienaSingsComponent } from './siena-sings/siena-sings.component';
import { LaunchComponent } from './launch/launch.component';
import { VideoTileComponent } from './components/video-tile/video-tile.component';
// import { MediaCollectionComponent } from './media-collection/media-collection.component';
// import { SearchtermFilterPipe } from './searchterm-filter.pipe';
// import { InstrumentFilterPipe } from './instrument-filter.pipe';
// import { LightboxModule } from 'ngx-lightbox';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { InstagramDOMService } from './instagram-dom.service';


@NgModule({
  declarations: [
    AppComponent,
    GlobalNavComponent,
    MainSearchComponent,
    FilterPipe,
    SongListComponent,
    MusicPlayerComponent,
    ClickStopPropigationDirective,
    AudioLoadDirective,
    AboutComponent,
    ContactComponent,
    // InterstitialComponent,
    HomeComponent,
    FooterComponent,
    DonateComponent,
    SubscriptionComponent,
    LoginComponent,
    ArtistListComponent,
    ArtistComponent,
    GlobalNavDarkComponent,
    // GunnarComponent,
    PeoplemusicComponent,
    SanitizeHtmlPipe,
    OtisPlayerComponent,
    AlbumsComponent,
    FeaturePlayerComponent,
    NotFoundComponent,
    PlayThisBeatComponent,
    HowItWorksComponent,
    // IgFeedComponent,
    SienaSingsComponent,
    LaunchComponent,
    VideoTileComponent,
    VideoPlayerComponent,
    // MediaCollectionComponent,
    // SearchtermFilterPipe,
    // InstrumentFilterPipe
  ],
  imports: [
    BrowserModule,
    RouterModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    // NgxAudioPlayerModule,
    Ng5SliderModule,
    AppRoutingModule,
    NgSelectModule,
    // LightboxModule,
    // NgxPageScrollCoreModule,
    // NgxPageScrollModule,
    ScrollToModule.forRoot()
  ],
  providers: [
	{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    ActiveTrackService,
    InstagramDOMService
  ],
  bootstrap: [AppComponent]
})


export class AppModule { }
