import { Component, OnInit, HostListener } from '@angular/core';
import { Track } from '../models/track';
import { ActiveTrackService } from '../active-track.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { MobileStatusService } from '../mobile-status.service';
import { ReactiveFormsModule, FormControl, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../_services';
import feature_data from "./launch_feature.json";
import { VideoTileComponent } from '../components/video-tile/video-tile.component';
// import { Lightbox } from 'ngx-lightbox';
import { VideoPlayerComponent } from '../components/video-player/video-player.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';


@Component({
  selector: 'app-launch',
  templateUrl: './launch.component.html',
  styleUrls: ['./launch.component.css'],
  animations: [
    trigger('move', [
      state('in', style({transform: 'scale(1.0)'})),
      state('out', style({transform: 'scale(1.1)'})),
      transition('in => out', animate('1s ease-in-out')),
      transition('out => in', animate('1s ease-in-out'))
    ]),
  ]
})


export class LaunchComponent implements OnInit {

	feature_track_config = feature_data;
	curTrack : Track = null;
	IsTrackPlaying : boolean = false;
	explainerImg : string = 'explainer_graphic.jpg';
	mobile : boolean = false;
	signUpForm : FormGroup;
  	email : FormControl;
	IsPlayerActive : boolean = false;
	curVideo: any;
	partner_list_string : string;
	whats_new_text : string;

	state = 'in';
	onEnd(event) {
		this.state = 'in';
		if (event.toState === 'in') {
			setTimeout(() => {
				this.state = 'out';
			}, 0);
		}
	}

	@HostListener('window:scroll', ['$event']) 
	onScroll($event) {
		console.log($event);
		console.log("scrolling");
	}


	constructor(private store : ActiveTrackService,
		private mobileSizeService : MobileStatusService,
		private authenticationService: AuthenticationService,
		private http: HttpClient) {
			
			this.getHtml('../../assets/copy/launch_whats_up.html')
			// this.partner_list_string = this.feature_track_config.partner_list.join(" &#8226; ")
	}

	open(feature: any): void {
		this.curVideo = feature;
		this.IsPlayerActive = true;
	}

	close(): void {
		this.IsPlayerActive = false;
		this.curVideo = null;
	}

	getHtml(path : string) {
		this.http.get(path, {responseType: 'text'}).subscribe(data => {
			this.whats_new_text = data;
		});
	}


	ngOnInit() {
		this.createFormControls();
    	this.createForm();
    	
		this.store.state$.subscribe(state => {
			this.updateTrackStatus(state);
		});
		this.setExplainerImage();

		this.mobileSizeService.state$.subscribe(mobile => {
		  this.mobile = mobile.mobileStatus;
		  this.setExplainerImage();
		});

	}


	// track the current selected track
	// and pause/play status of the current track
  	updateTrackStatus(state) {
		if (state.track == undefined || state.track == null) return;

	  	this.curTrack = state.track;
	  	if (state.IsPlaying == true || state.IsPlaying == false) { // change play state only if valid
	    	this.IsTrackPlaying = state.IsPlaying;
	  	}
  	}

	selectFeatureVideo(feature) {
		console.log("selected "+ JSON.stringify(feature));
	}


  	// switch between wide/tall explainer 
  	// explainer_graphic based on screen wodth
	setExplainerImage() {
		if (window.innerWidth <= 768)
	  		this.explainerImg = 'explainer_graphic_mobile.jpg';
	  	else
	  		this.explainerImg = 'explainer_graphic.jpg';
	}


	getiFrameWidth() : string {
		return '100%';
	}

	getiFrameHeight() : string {
		if (window.innerWidth >= 1050)
			return '300px';
		else if (window.innerWidth < 1050 && window.innerWidth >= 1000) 
			return '320px';
		else if (window.innerWidth < 1000 && window.innerWidth >= 700) 
			return '460px';
		else if (window.innerWidth < 700 && window.innerWidth >= 650 ) 
			return '400px';
		else if (window.innerWidth < 650 && window.innerWidth >= 600) 
			return '360px';
		else if (window.innerWidth < 600 && window.innerWidth >= 550) 
			return '300px';
		else if (window.innerWidth < 550) 
			return '220px';
	}


	getDisplayWidth() : number {
		return window.innerWidth;
	}

	createFormControls() {
	    this.email = new FormControl('',[
	      Validators.required,
	      Validators.pattern("[^ @]*@[^ @]*")
	    ]);
	}


	createForm() {
	    this.signUpForm = new FormGroup({
	      email: this.email,
	    });
	}


	submitForm() {
	    if (this.signUpForm.valid) {
	      this.authenticationService.registerEmail(this.signUpForm.value)
	        .pipe(first())
	        .subscribe(({ success }: { success: boolean }) => {
	          console.log({ success });
	        });
	      console.log("submitted: " + JSON.stringify(this.signUpForm.value));
	      this.signUpForm.reset();
	    } else {
	      console.log("form invalid spawn feedback");
	    }
	  	
	}

}
