import { 
  Component, 
  OnInit, 
  Input , 
  ViewChild, 
  ElementRef,
  HostListener,   
  Output, 
  EventEmitter 
} from '@angular/core';
import { Track } from '../models/track';  
import { ActiveTrackService } from '../active-track.service';
import { ActiveTrack } from '../models/active_track';
import { CurrentPlaylistService } from '../current-playlist.service';

import { Options } from 'ng5-slider';



@Component({
  selector: 'app-music-player',
  templateUrl: './music-player.component.html',
  styleUrls: ['./music-player.component.css'],
})


// @HostListener('ended', ['$event']);


export class MusicPlayerComponent implements OnInit {

	cur_track : Track = null;
  cur_trackList : any[] = [];
  @ViewChild('audioPlayer', { static: false }) audioplayer: ElementRef;
  @ViewChild('ng5sliderhandle', { static: false }) mpsliderhandle: ElementRef;
  @Output() rewEvent = new EventEmitter<string>();
  @Output() ffEvent = new EventEmitter<string>();

  play_control_image : string = "play.png"
  lastTrack : Track = null;
  IsTrackPlaying : boolean = false;
  actionDelay = null;
  audioListener = null;
  currentTrackDuration = null;
  currentTrackTime = null;
  IsScrubbing : boolean = false;
  scrubValue : number = 0;
  scrubSafetyTimer = null;
  scrubSafetyInterval = 20;

  // ng5 slider related
  options: Options = {
    floor: 0,
    ceil: 300,
    showSelectionBar : true,
    hidePointerLabels : true,
    animate: false
  };


  constructor(private store: ActiveTrackService,
    private playlistStore: CurrentPlaylistService) {
  }


  ngOnInit() {
    this.store.state$.subscribe(state => { // subscribe to the cur_track from state
      clearTimeout(this.actionDelay);
      this.cur_track = state.track;
      this.actionDelay = setTimeout( () => {
        if (state.IsPlaying == true || state.IsPlaying == false) // change play state only if explicit
          this.IsTrackPlaying = state.IsPlaying;

        if (this.cur_track != null && this.cur_track != undefined) {
          if (this.lastTrack != this.cur_track)
            this.handleAudioLoad();

          this.handleAudioPlayback();
          this.handlePlayPauseButton();
          this.lastTrack = this.cur_track;
        }
      },100);
    });

    this.playlistStore.state$.subscribe(state => { // subscribe to the cur_trackList from state
      this.cur_trackList = state.track_list;
    })

  }


  getDisplayName() {
    if (this.cur_track.title.includes(".wav"))
      return this.cur_track.title.replace(".wav","");
    else
      return this.cur_track.title.replace(".mp3","");
  }


  checkPlay() {
    if (this.cur_track == undefined) return;

    if (this.IsTrackPlaying == false || this.IsTrackPlaying == null && this.cur_track != null )
      this.playTrack();
    else if (this.IsTrackPlaying == true && this.cur_track != null )
      this.pauseTrack();
  }


  // set state to cur track and play
  playTrack() {
    let action : ActiveTrack = {
      track : this.cur_track,
      IsPlaying : true
    }
    this.store.setState(action);
  }


  // set state to cur track and pause
  pauseTrack() {
    //this.trackPaused.emit();
    let action : ActiveTrack = {
      track : this.cur_track,
      IsPlaying : false
    }
    this.store.setState(action);
  }


  selectNextTrack() {
    if ((this.cur_track == null && this.cur_track == undefined) || !this.cur_trackList ) return;

    if (this.cur_trackList.length > 1) {
      let listTrackIdx = this.cur_trackList.findIndex( t => t.isrc == this.cur_track.isrc);
      listTrackIdx++;
      if (listTrackIdx == this.cur_trackList.length)
        listTrackIdx = 0;
      let nextTrack = this.cur_trackList[listTrackIdx];
      let action : ActiveTrack = {
        track : nextTrack,
        IsPlaying : this.IsTrackPlaying
      }
      this.store.setState(action);
    }
  }


  selectPreviousTrack() {
    if (this.cur_track == null && this.cur_track == undefined ) return;
    
     if (this.cur_trackList.length > 1) {
      let listTrackIdx = this.cur_trackList.findIndex( t => t.isrc == this.cur_track.isrc);
      listTrackIdx--;
      if (listTrackIdx < 0)
        listTrackIdx = this.cur_trackList.length;
      let nextTrack = this.cur_trackList[listTrackIdx];
      let action : ActiveTrack = {
        track : nextTrack,
        IsPlaying : this.IsTrackPlaying
      }
      this.store.setState(action);
    }
  }


  // manual scrubbing the player handle
  updateTrackTime(event) {
    if (this.IsScrubbing != true) {
      this.IsScrubbing = true;
    }
    this.scrubValue = event.value;
  }

  grabbedHandle($event) {
    if (this.IsScrubbing != true) {
      this.IsScrubbing = true;
    }
  }


  releasedHandle() {
    
    if (this.audioListener == null) return;

    this.audioplayer.nativeElement.currentTime = this.scrubValue;

    // to avoid the song updating instantly from playback
    clearTimeout(this.scrubSafetyTimer);
    this.scrubSafetyTimer = setTimeout(() => {
      this.IsScrubbing = false;
    }, this.scrubSafetyInterval);
  }


  handleAudioLoad() {
    if (this.cur_track != null && this.cur_track != this.lastTrack) {
      let file = 'https://media.tracktribe.com/tracks/' + this.cur_track.title;
      this.audioplayer.nativeElement.src = file;
      this.audioplayer.nativeElement.load();


      // initialize the audioListener the first time any track is loaded
      if (this.audioListener == null) {
        this.audioListener = 'set';
        this.audioplayer.nativeElement.addEventListener("timeupdate", (e) => {
          if (this.IsTrackPlaying == true && this.IsScrubbing == false)
            this.currentTrackTime = this.audioplayer.nativeElement.currentTime;
            this.sanitzeTimeValue(this.currentTrackTime);
        });
        this.audioplayer.nativeElement.addEventListener("loadedmetadata", (e) => {
          this.setTrackAttributes(this.audioplayer.nativeElement.duration);
        });
        this.audioplayer.nativeElement.addEventListener("ended", (e) => {
          if (this.IsTrackPlaying == true && this.IsScrubbing == false)
            this.selectNextTrack();
        });
      }
    }
  }


  getTrackDuration() : string {
    if (this.cur_track == null || this.cur_track == undefined)
      return this.currentTrackDuration;
    else
      return this.cur_track.duration;
  }


  setTrackAttributes(duration) {
    this.currentTrackTime = 0;
    this.currentTrackDuration = duration
    const newOptions: Options = Object.assign({}, this.options);
    newOptions.ceil = duration;
    this.options = newOptions;
    // console.log("options : " + JSON.stringify(this.options));
  }


  sanitzeTimeValue(val) : string {
    let sec_num = parseInt(val, 10); // don't forget the second param
    let hours   = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    let seconds = sec_num - (hours * 3600) - (minutes * 60);
    let disp_seconds = seconds.toString();
    let disp_hours= hours.toString();
    let disp_minutes = minutes.toString();
    
    if (hours   < 10) {disp_hours   = "0"+ disp_hours;}
    
    if (seconds < 10) {disp_seconds = "0"+disp_seconds;}
    
    return disp_minutes.toString()+':'+disp_seconds.toString();
  }


  getRawTrackTime() : number {
    if (this.audioListener == null || this.currentTrackTime == null)
      return 0
    else
      return this.currentTrackTime;
  }

  getDisplayTrackCurrentTime() : string {
    if (this.audioListener == null || this.currentTrackTime == null)
      return null;
    else 
      return this.sanitzeTimeValue(this.currentTrackTime);
  }

  
  getDisplayTrackDuration() {
    if (this.audioListener == null || this.currentTrackDuration == null) 
      return null;
    else 
      return this.sanitzeTimeValue(this.currentTrackDuration);
  }



  handleAudioPlayback() {
    if ( this.IsTrackPlaying == true ) {
      this.audioplayer.nativeElement.play();
    } else if ( this.IsTrackPlaying == false ) {
      this.audioplayer.nativeElement.pause();
    }
  }


  handlePlayPauseButton() {
    if (this.IsTrackPlaying == true) {
      this.play_control_image = "pause_ico.png";
    } else {
      this.play_control_image = "play_ico.png";
    }
  }


}
