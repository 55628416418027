import { Store } from 'rxjs-observable-store';
import { ActiveTrack } from './models/active_track';
import * as i0 from "@angular/core";
export class ActiveTrackService extends Store {
    constructor() {
        super(new ActiveTrack());
        // this._trackState$ = new BehaviorSubject(null)
        // 	this.trackState$ = this._trackState$.asObservable();
    }
    setSongData(data) {
        // super.state.song_data = data;
        // console.log(super.state.song_data);
    }
    goNextTrack() {
        //return this._trackState$.getValue();
        // let t = super.state.track.id;
        // t++;
        // if (t > 100)
        //   t = 1;
        // let result = super.state.song_data.filter(obj => {
        //   return obj.id === t;
        // })
        // console.log("going to : " + t);
    }
    goPreviousTrack() {
        // let t = super.state.track.id;
        // t--;
        // if (t < 1)
        //   t = 100;
        // let result = super.state.song_data.filter(obj => {
        //   return obj.id === t;
        // })
        // console.log("going to : " + t);
    }
}
ActiveTrackService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ActiveTrackService_Factory() { return new ActiveTrackService(); }, token: ActiveTrackService, providedIn: "root" });
