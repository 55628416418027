import { Injectable } from '@angular/core';
import { Store } from 'rxjs-observable-store';
import { Observable, BehaviorSubject } from 'rxjs';
import { SelectedFilterList } from './models/selected_filter_list';


@Injectable({
  providedIn: 'root'
})


export class SelectedFilterService extends Store<SelectedFilterList>{

	// selectedFilters : SelectedFilterList;
	

  	constructor() { 
  		super(new SelectedFilterList());
  	}

  	// ngOnInit() {
  	// 	// console.log(this.selectedFilters);
  	// }

  // 	addOption(option,group) {
  // 		console.log("adding " + option + " to  " + group);
  // 		if (this.selectedFilters["group"] != undefined) {
  // 			if (!this.selectedFilters['group'].includes(option))
  //     			this.selectedFilters['group'].push(option);
  // 		}
  // 		console.log(this.selectedFilters);
  // 	}

  // 	removeOption(option,group) {
  // 		if (this.selectedFilters.hasOwnProperty(group)) {
		// 	if (this.selectedFilters['group'].includes(option)) {
		// 	  let index = this.selectedFilters['group'].indexOf(option);
		// 	  this.selectedFilters['group'].splice(index,1);
		// 	}
		// }
  // 		console.log(this.selectedFilters);
  // 	}
  	

}
