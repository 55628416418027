
<div class="player-container">

	

	<div class="info-group">
			<!-- <img *ngIf="cur_track != null && cur_track.img_thumb != ''" class="track-thumbnail" src="../assets/images/art_track_thumbs/SQ/{{cur_track.img_thumb}}" /> -->
	
		<div class="player-controls-mobile">
			<img class="control play" src="./assets/images/{{play_control_image}}" (click)="checkPlay()">
		</div>
		<div class="info-details" *ngIf="cur_track != null">
			<div class="track-title">{{ getDisplayName() }}</div>
			<div class="artist-name">{{ cur_track.provider }}</div>
		</div>
	</div>

	<div class="control-group" appClickStopPropigation>
		<div class="player-controls">
			<img class="control skip" src="./assets/images/rwd_ico.png" (click)="selectPreviousTrack()">
			<img class="control play" src="./assets/images/{{play_control_image}}" (click)="checkPlay()">
			<img class="control skip" src="./assets/images/ffwd_ico.png" (click)="selectNextTrack()">
		</div>
		<div class="track-time" (mousedown)="grabbedHandle($event)">
			<div class="time-value">{{getDisplayTrackCurrentTime()}}</div>
	  		<ng5-slider 
	  			[(value)]="currentTrackTime" 
	  			[options]="options" 
	  			(userChangeEnd)="releasedHandle()"
	  			(userChange)="updateTrackTime($event)"
	  			(mousedown)="grabbedHandle($event)">
	  		</ng5-slider>

			<div class="time-value right-aligned">{{getDisplayTrackDuration()}}</div>
		</div>
		<audio #audioPlayer *ngIf="cur_track != null" preload="metadata" appAudioLoad>
		  <!-- <source src="horse.ogg" type="audio/ogg"> -->
		  <source src="./assets/audio/{{cur_track.title}} " type="audio/mpeg">
			Your browser does not support the audio element.
		</audio>
	</div>

	<div class="secondary-controls"></div>
</div>

