import { Injectable } from '@angular/core';
import { Store } from 'rxjs-observable-store';
import { Observable, BehaviorSubject } from 'rxjs';
import { MobileStatus } from './models/mobile_status';


@Injectable({
  providedIn: 'root'
})


export class MobileStatusService extends Store<MobileStatus> {
	
	mobile : boolean = false;

  	constructor() { 
  		super(new MobileStatus());
   	}

	updateScreenWidth(width) {

		if (width <= 768)
		  this.mobile = true;
		else
		  this.mobile = false;

		// console.log("mobile service status : " + this.mobile);
	}


	// getMobile() {
	// 	return this.mobile;
	// }

}
